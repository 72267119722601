import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as UserStore from '../store/User';
import * as CommonStore from '../store/Common';
import MessageError from './shared/MessageError';
import LoadingButton from './shared/LoadingButton';
import T from 'i18n-react';
import LoadingOverlay from './shared/LoadingOverlay';

const Scroll = require('react-scroll');
const qs = require('querystringify');

class AccountVerification extends Component {

    constructor(props) {
        super(props);

        const params = qs.parse(props.location.search);

        this.state = {
            verified: true,
            token: params.t
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('verification.title');
    }

    componentDidUpdate(prevProps) {
        if (this.props.initiated !== prevProps.initiated) {
            this.props.validateVerificationCode(this.state.token);
        }
    }

    onRequestNewLink = () => {
        this.props.regenerateVerificationCode(this.state.token);
    }

    getMessageError = () => {
        if (this.props.userSourceError === 'ExpiredToken') {
            var textToAppend = T.translate('verification.requestNewToken');
            return this.props.userErrorMessage + ' ' + textToAppend;
        }

        return this.props.userErrorMessage;
    }

    getMessageVerification = () => {
        if (this.props.accountVerificationConfirmation) {
            return T.translate('verification.verificationCompleted');
        } else if (this.props.verificationCodeConfirmation) {
            return T.translate('login.verificationCodeSent');
        } else if (this.props.accountVerified) {
            return T.translate('verification.accountVerified');
        }
        return '';
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title-home">{T.translate('verification.myAccount')}</div>
                    </div>
                    <div className="diverter" />
                    <h6 className="card-title mb-3">{T.translate('verification.pageTitle')}</h6>

                    {!this.props.accountVerificationConfirmation && <div>
                        <p>
                            {T.translate('verification.pageSubTitle', { customerCarePhone: this.props.systemProperties.customerServicePhone })}
                        </p>
                    </div>}

                    {(this.props.accountVerificationConfirmation || this.props.verificationCodeConfirmation || this.props.accountVerified) &&
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <p>
                                {this.getMessageVerification()}
                                </p>
                            </div>
                        </div>
                    }

                    <LoadingOverlay loading={this.props.commonLoading || this.props.userLoading} />

                    {this.props.userErrorMessage &&
                        <MessageError>
                            <div>
                                <p>{this.getMessageError()}</p>
                                {this.props.userSourceError === 'ExpiredToken' &&
                                    <LoadingButton
                                        type="button"
                                        className="btn btn-light"
                                        onClick={this.onRequestNewLink}
                                        loading={this.props.userLoading ? 'true' : 'false'}>
                                        {T.translate('login.sendVerificationCode')}
                                    </LoadingButton>
                                }
                            </div>
                        </MessageError>
                    }

                    <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('verification.backToLogin')}</NavLink>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(AccountVerification);
