import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { actionCreators } from '../store/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingButton from './shared/LoadingButton';
import MessageError from './shared/MessageError';
import T from 'i18n-react';

const Scroll = require('react-scroll');
const Recaptcha = require('react-recaptcha');

class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            verified: false,
            userName: '',
            accountNumber: ''
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('forgot.forgotPasswordTitle');

        this.props.clearUserState();
    }

    onChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value.trim();

        this.setState(state);
    }

    verifyCallback = (response) => {
        if (response) {
            this.setState({ verified: true });
        }
    }

    onClick = () => {   
        this.props.forgotPassword(this.state);
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title-home">{T.translate('forgot.myAccount')}</div>
                    </div>
                    <div className="diverter" />
                    <h6 className="card-title mb-3">{T.translate('forgot.forgotYourPassword')}</h6>
                    {!this.props.forgotPasswordConfirmation && <div>
                        <p>{T.translate('forgot.enterUsername')}</p>
                        <div className="form-group mt-4">
                            <label htmlFor="accountNumber">{T.translate('forgot.accountNumber')}</label>
                            <input type="text" name="accountNumber" className="form-control" value={this.state.accountNumber} onChange={this.onChange} autoComplete="off" />
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="userName">{T.translate('forgot.username')}</label>
                            <input type="text" name="userName" className="form-control" value={this.state.userName} onChange={this.onChange} autoComplete="off" />
                        </div>
                        <div className="mb-4">
                            <Recaptcha
                                sitekey={this.props.systemProperties.reCaptchaKey}
                                verifyCallback={this.verifyCallback}
                            />
                        </div>
                    </div>}

                    {this.props.forgotPasswordConfirmation && 
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <p>
                                {T.translate('forgot.ifEmailAssociated', this.props.systemProperties)}
                                </p>
                            </div>
                        </div>
                    }

                    <MessageError>{this.props.userErrorMessage}</MessageError>

                    {!this.props.forgotPasswordConfirmation &&
                        <LoadingButton disabled={!this.state.verified || !this.state.userName || !this.state.accountNumber || this.props.userLoading || this.props.commonLoading} className="btn btn-primary btn-lg" onClick={this.onClick} loading={this.props.userLoading || this.props.commonLoading ? 'true' : 'false'}>{T.translate('forgot.continue')}</LoadingButton>
                    }

                    <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('forgot.backToLogin')}</NavLink>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common }),
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ForgotPassword);
