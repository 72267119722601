import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as UserStore from '../store/User';
import * as CommonStore from '../store/Common';
import * as AccountStore from '../store/Account';
import LoadingButton from './shared/LoadingButton';
import MessageError from './shared/MessageError';
import T from 'i18n-react';
import InputMask from 'react-input-mask';
import validator from 'validator';

const Scroll = require('react-scroll');
const Recaptcha = require('react-recaptcha');

class UserNameUpdate extends Component {

    constructor(props) {
        super(props);

        let userNameMobilePhone = '';
        let userNameEmailAddress = '';

        

        if (props.accountPersons && props.accountPersons.length > 0 && props.accountPersons[0].persons && props.accountPersons[0].persons.length > 0) {

            const p = props.accountPersons[0].persons[0];

            userNameEmailAddress = p.emailAddress;

            if (p.personContacts) {
                let cell = p.personContacts.filter(x => x.contactTypeID === 1)[0];

                if (cell && cell.contactValue) {
                    userNameMobilePhone = cell.contactValue;
                }
            }
        }

        this.state = {
            verified: true,
            userNameEmailAddress,
            userNameMobilePhone,
            useEmailAddress: true,
            fields: [
                { name: 'userNameEmailAddress', isEmail: true, conditionField: 'useEmailAddress', conditionValue: true },
                { name: 'userNameMobilePhone', conditionField: 'useEmailAddress', conditionValue: false }
            ]
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('usernameUpdate.title');

        this.props.clearUserState();
    }


    onChange = (e) => {

        let state = this.state;
        let fields = state.fields.filter(x => x.name === e.target.name);

        if (fields && fields.length === 1) {

            let field = fields[0];

            if (!field.isRequired && field.isRequiredError && field.conditionField && e.target.value && field.conditionValue === state[field.conditionField]) {
                field.isRequiredError = false;
            }

            if (!field.isRequiredError && field.isEmailError && validator.isEmail(e.target.value)) {
                field.isEmailError = false;
            }
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;

        this.state.fields.map((field) => {

            field.value = this.state[field.name];
            //clear errors
            field.isRequiredError = false;
            field.isEmailError = false;

            if (!field.isRequiredError && field.conditionField && !field.value && field.conditionValue === state[field.conditionField]) {
                field.isRequiredError = true;
                valid = false;
            }

            if (!field.isRequiredError && field.isEmail && field.value && !validator.isEmail(field.value)) {
                field.isEmailError = true;
                valid = false;
            }

        });

        this.setState(state);

        if (valid) {
            this.props.setError('');
        } else {
            this.props.setError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    verifyCallback = (response) => {
        if (response) {
            this.setState({ verified: true });
        }
    }

    onClick = () => {
        if (this.isFormValid()) {
            this.props.updateUserName(this.state);
        }
    }

    toggleUseEmailAddress = (e) => {
        this.setState({ useEmailAddress: !this.state.useEmailAddress });
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title">{T.translate('usernameUpdate.pageTitle')}</div>
                    </div>
                    <div className="diverter" />
                    {!this.props.accountVerificationConfirmation && <div>
                        <p>{T.translate('usernameUpdate.pageSubTitle')}</p>
                        <p>{T.translate('usernameUpdate.pageSubTitle2')}</p>

                        {this.state.useEmailAddress && <div className="form-group mt-4">
                            <label htmlFor="userNameEmailAddress">{T.translate('register.emailAddress')}</label>
                            <label style={{ float: 'right' }}><a href="javascript:;" onClick={this.toggleUseEmailAddress}>{T.translate('register.useMobileNumber')}</a></label>
                            <input type="text" id="userNameEmailAddress" name="userNameEmailAddress" onChange={this.onChange} value={this.state.userNameEmailAddress} className={"form-control " + (this.state.fields[0].isRequiredError || this.state.fields[0].isEmailError ? 'is-invalid' : '')} />
                            {this.state.fields[0].isRequiredError && <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>}
                            {this.state.fields[0].isEmailError && <div className="invalid-feedback">{T.translate('common.provideValidEmail')}</div>}
                        </div>}

                        {!this.state.useEmailAddress && <div className="form-group mt-4">
                            <label htmlFor="userNameMobilePhone">{T.translate('register.mobilePhone')}</label>
                            <label style={{ float: 'right' }}><a href="javascript:;" onClick={this.toggleUseEmailAddress}>{T.translate('register.useEmailAddress')}</a></label>
                            <InputMask mask="(999) 999-9999" id="userNameMobilePhone" name="userNameMobilePhone" onChange={this.onChange} value={this.state.userNameMobilePhone} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>}

                        <div className="mb-4">
                            <Recaptcha
                                sitekey={this.props.systemProperties.reCaptchaKey}
                                verifyCallback={this.verifyCallback}
                            />
                        </div>
                    </div>}

                    {this.props.accountVerificationConfirmation &&
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <p>
                                    {T.translate('verification.verificationCompleted')}
                                </p>
                            </div>
                        </div>
                    }

                    <MessageError>{this.props.userErrorMessage || this.props.accountErrorMessage}</MessageError>

                    {!this.props.accountVerificationConfirmation &&
                        <LoadingButton disabled={!this.state.verified || this.props.userLoading || this.props.accountLoading} className="btn btn-primary btn-lg" onClick={this.onClick} loading={this.props.userLoading || this.props.accountLoading ? 'true' : 'false'}>{T.translate('common.submit')}</LoadingButton>
                    }

                    <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('verification.backToLogin')}</NavLink>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common, ...state.account }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(UserNameUpdate);
