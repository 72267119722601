import T from 'i18n-react';
import Moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import SecureStorage from 'secure-web-storage';
import Cookies from 'universal-cookie';
import { history } from '../index';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es);


var CryptoJS = require("crypto-js");
var passwordValidator = require('password-validator');

const RECAPTCHA_COOKIE_KEY = '6LdjG4sUAAAAAJf5HlimijoJ6qPH-HeuvQSSigz6';

const initialState = {
    serviceUrl: '',
    companyID: 0,
    initiated: false,
    publicToken: '',
    commonLoading: false,
    commonErrorMessage: '',
    account: {
        daysRemaining: 0,
        daysRemainingCount: 0,
        servicePoints: [],
        accountServicePoints: []
    },
    renewalData: {},
    systemProperties: {
        //Sorted alphabetically
        bankAccountTypes: [],
        creditCards: [],
        customerServiceEmail: '',
        customerServiceHoursEN: '',
        customerServiceHoursES: '',
        customerServicePhone: '',
        emailContactInfoRequired: false,
        isEnrollmentAddOnEnabled: false,
        isLoaded: false,
        isSlimCDEnabled: false,
        marketerBillingAddress: '',
        marketerFacebookUrl: '',
        marketerLinkedinUrl: '',
        marketerMailingAddress: '',
        marketerTosUrl: '',
        marketerTwitterUrl: '',
        messagingUtilityName: '',
        meterStatus: [],
        myAcctMviFutureDaysAllowed: 0,
        myAcctMvoFutureDaysAllowed: 0,
        myAcctAutopayMinimumPayment: 0,
        myAcctDisplayPaymentProvider: { imageName: 'online-empty.png', index: 0 },
        myAcctOtherPaymentTenderTypes: {
            payByMail: false,
            payByPhone: false,
            payWithCash: false
        },
        myAcctPaymentTenderTypes: {
            cash: false,
            check: false,
            creditCard: false,
            debitCard: false,
            eCheck: false,
            eCheckNoPost: false
        },
        myAcctRenewalUrlRedirection: '',
        myAcctShowPayextension: false,
        myAcctShowPlansDocuments: false,
        paymentCardTypes: [],
        paymentusCreditCardCharge: 0.0,
        paymentusECheckCharge: 0.0,
        postpayPayExtensionMaxAnnualRequests: 0,
        postpayPayExtensionMaxDays: 0,
        myAcctAllowMailAddressEdit: false,
        myacctOtherAmountProcessingFee: 0.0,
        myacctReferralTitle: '',
        myacctReferralDescription: '',
        myacctReferralLogo: '',
        reCaptchaKey: getRecaptchaKey(),
        smsContactInfoRequired: false
    },
    nonWorkingDaysByTdu: [],
    statesList: [],
    uspsAddress: { status: 0 },//After if status uspsAddressStatus.FOUND it will contain basic address info
    accountID: 0,
    accountServicePointID: 0,
    servicePointID: 0,
    lang: '',
    langID: 1,
    ip: '',
    constants: {
        uspsAddressStatus: {
            PENDING: 0,
            FOUND: 1,
            NOT_FOUND: 2
        },
        addressSource: {
            CUSTOM: 0,
            USPS: 1
        },
        searchStatus: {
            PENDING: 0,
            FOUND: 1,
            NOT_FOUND: 2
        },
        commodity: {
            ELECTRICITY: 1,
            NATURAL_GAS: 2,
            WATER: 3,
            SEWER: 4,
            IRRIGATION: 5,
            GARBAGE: 6
        },
        paymentTypes: {
            DEBIT: 'debit',
            CREDITCARD: 'credit card',
            ECHECK: 'e-check'
        },
        serviceOrderReasonType: {
            INSPECTION_ON_RPZ_DEVICE: 223,
            INSPECTION_ON_DCV_DEVICE: 224
        }
    }
};

export const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Authorization': ''
};

export const handleResponse = function (response) {
    if (response.status >= 400) {
        console.log(response);

        if (response.status === 401 || response.status === 403) {
            if (window.location.pathname !== '/') {
                window.location.replace('/');
            }
            throw new Error('Unauthorized');
        } else {
            return response.json().then(function (err) {
                throw new Error(err);
                })
                .catch(error => {
                    console.error(error);

                    if (error.toString().includes("Unexpected token '<'"))
                        throw new Error('Unexpected API Server Error');

                    throw new Error(error);
                });
        }
    } else {
        return response.json();
    }
};

let KEY = 'd7cb0eb2-334f-4983-bfeb-34b3cefd6930';

export const secureStorage = new SecureStorage(sessionStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, KEY);

        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, KEY);

        data = data.toString();

        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, KEY);

        data = data.toString(CryptoJS.enc.Utf8);

        return data;
    }
});

export const creditInfoList = [
    {
        name: "visa",
        type: "1",
        regEx: "^4",
        mask: "9999-9999-9999-9999",
        minLength: 13,
        maxLength: 16
    },
    {
        name: "masterCard",
        type: "2",
        literalregEx: true,
        regEx: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
        mask: "9999-9999-9999-9999"
    },
    {
        name: "discover",
        type: "3",
        regEx: "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)",
        mask: "9999-9999-9999-9999"
    },
    {
        name: "amex",
        type: "4",
        regEx: "^3[47]",
        mask: "9999-999999-99999"
    },
    {
        name: "dinersClub",
        type: "",
        regEx: "^3[68]",
        mask: "9999-999999-9999"
    },
    {
        name: "dinersClub-CarteBlanche",
        type: "",
        regEx: "^30[0-5]",
        mask: "9999-999999-9999"
    },
    {
        name: "jdc",
        type: "",
        regEx: "^35(2[89]|[3-8][0-9])",
        mask: "9999-9999-9999-9999"
    },
    {
        name: "visaElectron",
        type: "",
        regEx: "^(4026|417500|4508|4844|491(3|7))",
        mask: "9999-9999-9999-9999"
    },
];

export const actionCreators = {

    init: () => (dispatch, getState) => {

        var state = getState().common;

        if (!state.lang) {
            setLanguage(dispatch);
        }

        if (state.initiated) {
            setAccount(dispatch, getState);
            return;
        }

        const config = require(`../config/${process.env.REACT_APP_CLIENT}.json`);

        dispatch({ type: 'SET_COMMON_CONFIGURATION', config });

        fetch(`api/data/init`, { credentials: "same-origin" })
            .then(response => {
                if (response.status >= 400) {
                    return response.json().then(function (err) {
                        console.error(err);

                        if (err['message'] !== undefined)
                            throw new Error(err['message']);

                        throw new Error(err);
                    });
                } else {
                    return response.clone().json();
                }
            })
            .then(data => {
                let accessToken = data.result.accessToken;

                if (accessToken) {
                    dispatch({ type: 'RESPONSE_COMMON_INIT', initiated: true, publicToken: 'bearer ' + accessToken });
                    configurationInit(dispatch, getState);
                } else {
                    dispatch({ type: 'SET_COMMON_ERROR', errorMessage: 'There was an error with app initialization - please reload the app in the browser and if the error persists call customer service.' });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_COMMON_ERROR', errorMessage: error.message });
            });

        fetch('https://jsonip.com/', { method: 'GET' })
            .then(handleResponse)
            .then(data => {

                if (data && data.ip) {
                    dispatch({ type: 'SET_IP_ADDRESS', ip: data.ip });
                }
            })
            .catch(function (error) {
                console.log(error.message);
            });

        dispatch({ type: 'REQUEST_COMMON_INIT' });

    },

    toggleLang: () => (dispatch, getState) => {
        let lang = getState().common.lang === 'es' ? 'en' : 'es';
        setLanguage(dispatch, lang);
    },

    requestUpsAddressCheck: (address) => (dispatch, getState) => {
        upsAddressCheck(dispatch, getState, address);
    },

    requestNonWorkingDays: (distributionServiceProviderID, workingDaysGroupID) => (dispatch, getState) => {
        nonWorkingDaysNStates(dispatch, getState, { nonWorkingDaysData: { distributionServiceProviderID, workingDaysGroupID } });
    },

    requestStatesList: () => (dispatch, getState) => {
        nonWorkingDaysNStates(dispatch, getState, { statesData: {} });
    },

    requestNonWorkingDaysNStates: (distributionServiceProviderID, workingDaysGroupID) => (dispatch, getState) => {
        nonWorkingDaysNStates(dispatch, getState, { nonWorkingDaysData: { distributionServiceProviderID, workingDaysGroupID }, statesData: {} });
    },

    setAccount: () => (dispatch, getState) => {
        setAccount(dispatch, getState);
    },

    setAccountServicePoint: (accountServicePointID, servicePointID) => (dispatch, getState) => {
        dispatch({ type: 'SET_ACCOUNT_SERVICE_POINT', accountServicePointID, servicePointID });
    },

    setAccountID: (accountID) => (dispatch, getState) => {
        secureStorage.setItem('accountID', accountID);
        dispatch({ type: 'SET_ACCOUNT_SERVICE_POINT', accountServicePointID: 0, servicePointID: 0 });
    },

    clearCommonState: () => (dispatch) => {
        dispatch({ type: 'CLEAR_COMMON_STATE' });
    },

    resetCommonState: (resetInfo) => (dispatch) => {
        dispatch({ type: 'RESET_COMMON_STATE_VALUE', resetInfo });
    },

    setCommonError: (errorMessage) => (dispatch) => {
        dispatch({ type: 'SET_COMMON_ERROR', errorMessage: errorMessage });
    },

    getPrivateToken: () => () => {
        return secureStorage.getItem('privateToken');
    },

    //CV ZD14560 2020/03/26 adding this method to read the paymentStripePK configuration.
    getPaymentStripePK: () => (dispatch, getState) => {

        var state = getState().common;

        return state.paymentStripePK;
    },

    //CV JIRA_1113 2020/09/01 adding this method to read the configuration if a client is regulated or not.
    isRegulatedClient: () => (dispatch, getState) => {

        var state = getState().common;

        return state.isRegulatedClient === true;
    }
};


const setAccount = function (dispatch, getState) {

    try {
        let st = getState();
        let accountID = secureStorage.getItem('accountID');
        let personID = getPersonID(st, accountID);

        if (accountID) {

            headers.Authorization = secureStorage.getItem('privateToken');
            let accountServicePointID = st.common.accountServicePointID;
            let servicePointID = st.common.servicePointID;

            //TODO: review webmethod
            fetch(`${st.common.serviceUrl}/AccountSummary?AccountID=${accountID}&AccountServicePointID=${accountServicePointID}&servicePointID=${servicePointID}&PersonID=${personID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
                .then(handleResponse)
                .then(data => {

                    if (data) {

                        let address1 = '';
                        let address2 = '';
                        let city = '';
                        let state = '';
                        let postalCode = '';
                        let emailAddress = '';
                        let homePhone = '';
                        let mobilePhone = '';
                        let languageID = data.languageID;
                        let billDeliveryMethodPaper = false;
                        let billDeliveryMethodElectBill = false;
                        let notificationChannelEmail = false;
                        let notificationChannelSms = false;
                        let twoFactorAuthRequired = false;
                        let hasRenewalOffers = false;

                        //set count based on days remaining - max is 30 to display in dashboard counter
                        data.daysRemainingCount = data.daysRemaining > 30 ? 30 : data.daysRemaining;

                        if (data.mailingAddress) {
                            address1 = data.mailingAddress.address1;
                            address2 = data.mailingAddress.address2 ? data.mailingAddress.address2 : '';
                            city = data.mailingAddress.city;
                            state = data.mailingAddress.state;
                            postalCode = data.mailingAddress.postalCode;
                        }

                        if (data.person) {

                            emailAddress = data.person.emailAddress;
                            twoFactorAuthRequired = data.person.twoFactorAuthRequired;
                            let phone = {};

                            if (data.person.personContacts && data.person.personContacts.length) {
                                phone = data.person.personContacts.filter(x => x.contactTypeID === 2)[0];
                                if (phone) {
                                    homePhone = phone.contactValue;
                                }

                                phone = data.person.personContacts.filter(x => x.contactTypeID === 1)[0];
                                if (phone) {
                                    mobilePhone = phone.contactValue;
                                }
                            }

                            if (data.person.notificationChannels && data.person.notificationChannels.length) {

                                if (data.person.notificationChannels.filter(x => x.notificationChannelID === 1)[0]) {
                                    notificationChannelEmail = true;
                                }

                                if (data.person.notificationChannels.filter(x => x.notificationChannelID === 2)[0]) {
                                    notificationChannelSms = true;
                                }
                            }
                        }

                        if (data.billDeliveryMethod && data.billDeliveryMethod.length) {
                            billDeliveryMethodPaper = data.billDeliveryMethod.filter(x => x.billDeliveryMethodID === 1)[0] ? true : false;
                            billDeliveryMethodElectBill = data.billDeliveryMethod.filter(x => x.billDeliveryMethodID === 2)[0] ? true : false;
                        }

                        hasRenewalOffers = data.hasRenewalOffers;

                        data.customer = {
                            address1,
                            address2,
                            city,
                            state,
                            postalCode,
                            emailAddress,
                            homePhone,
                            mobilePhone,
                            languageID,
                            billDeliveryMethodPaper,
                            billDeliveryMethodElectBill,
                            notificationChannelEmail,
                            notificationChannelSms,
                            twoFactorAuthRequired,
                            hasRenewalOffers
                        };

                        //servicePoints contains productID from active SA tied
                        if (accountServicePointID <= 0 && data.servicePoints && data.servicePoints.length > 0) {
                            accountServicePointID = data.servicePoints[0].accountServicePointID;
                            servicePointID = data.servicePoints[0].servicePointID;
                        }

                        //show meter status name based on language if we have a match
                        if (data.meterStatusID && st.common.systemProperties.meterStatus) {
                            let meterStatus = st.common.systemProperties.meterStatus.filter(x => x.value == data.meterStatusID)[0];
                            if (meterStatus) {
                                data.meterStatus = meterStatus.name;
                            }
                        }

                        //show usage tabs and commodity information based on active service agreements
                        if (data.serviceAgreements && data.serviceAgreements.length) {
                            data.electricity = data.serviceAgreements.filter(x => x.commodityID === 1)[0];
                            data.water = data.serviceAgreements.filter(x => x.commodityID === 3)[0];
                        }

                        if (data.locationServices) {

                            data.locationServices.map((locationSvc) => {

                                locationSvc["linkedServiceTypeID"] = -1;

                                var linkedLocations = data.locationServices.filter(x => x.linkedAccountServicePointID === locationSvc.accountServicePointID);
                                if (linkedLocations && linkedLocations.length > 0) {
                                    //checking if service location is referenced/linked by any other (if so, we treat it as parent)
                                    locationSvc["linkedServiceTypeID"] = linkedLocations[0].serviceTypeID;
                                } else if (locationSvc.linkedAccountServicePointID && locationSvc.linkedAccountServicePointID > 0) {
                                    //checking if service location is references a parent service (if so, we treat it as child)
                                    locationSvc["linkedServiceTypeID"] = undefined;
                                }

                                locationSvc.assets = [];

                                if (data.regulatedDetails) {
                                    locationSvc.assets = data.regulatedDetails["LocationAssets"];
                                }                                

                                return locationSvc;
                            });
                        }

                        dispatch({ type: 'SET_ACCOUNT', account: data, accountServicePointID, servicePointID, accounts: secureStorage.getItem('accounts') });

                    } else {
                        dispatch({ type: 'SET_COMMON_ERROR', errorMessage: '' });
                    }

                })
                .catch(function (error) {
                    dispatch({ type: 'SET_COMMON_ERROR', errorMessage: error.message });
                });

            dispatch({ type: 'REQUEST_COMMON_START' });
        }
    } catch (e) {
        console.log(e);
    }

};

const configurationInit = function (dispatch, getState) {
    try {

        let state = getState();
        const companyID = state.common.companyID;

        headers.Authorization = state.common.publicToken;

        //MyAccount category (SystemCategoryID = 16)
        const requestCategory = { SystemCategoryID: 16, CompanyID: companyID };
        const requestDataList = { listType: ['BANK_ACCOUNT_TYPE', 'CREDIT_CARDS', 'METER_STATUS'], languageID: state.common.langID };
        const requestSystemPropertyList = {
            CompanyID: companyID, SystemPropertyNames: [
                'CUSTOMER_SERVICE_PHONE', 'CUSTOMER_SERVICE_HOURS_EN', 'CUSTOMER_SERVICE_HOURS_ES', 'CUSTOMER_SERVICE_EMAIL', 'MARKETER_TOS_URL',
                'MESSAGING_UTILITY_NAME', 'MARKETER_BILLING_ADDRESS', 'MARKETER_MAILING_ADDRESS', 'MARKETER_FACEBOOK_URL', 'MARKETER_TWITTER_URL', 'MARKETER_LINKEDIN_URL',
                'EMAIL_CONTACT_INFO_REQUIRED', 'SMS_CONTACT_INFO_REQUIRED', 'BILL_CONSUMPTION_NUMBER_OF_DECIMALS', 'ENROLLMENT_ADD_ON_CHARGES_ENABLED', 'MEMBERSHIP_ENABLED', 'MYACCOUNT_DISPLAY_MEMBERPROGRAMS',
                'MYACCOUNT_REFERRAL_TITLE', 'MYACCOUNT_REFERRAL_DESCRIPTION', 'MYACCOUNT_REFERRAL_LOGO', 'ENABLE_SLIMCD_PAYMENTS', 'PAYMENT_SLIMCD_USERNAME', 'MYACCOUNT_PAYMENTUS_CC_CHARGE_VALUE',
                'MYACCOUNT_PAYMENTUS_ECHECK_CHARGE_VALUE', 'BILL_DOCUMENT_GENERATION_CHART_SETTINGS', 'MYACCOUNT_RECAPTCHA_KEY'
            ]
        };

        const queryStringCategory = Object.keys(requestCategory).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestCategory[key])).join('&');
        const queryStringSystemPropertyList = toQueryString(requestSystemPropertyList, '');
        const qeryStringDataList = toQueryString(requestDataList, '');

        const fetchSystemPropertiesByCategory = fetch(`${state.common.serviceUrl}/SystemPropertiesByCategory?${queryStringCategory}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(handleResponse);

        const fetchOtherSystemProperties = fetch(`${state.common.serviceUrl}/SystemProperties?${queryStringSystemPropertyList}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(handleResponse);
     
        const fetchDataListTypes = fetch(`${state.common.serviceUrl}/DataListType?${qeryStringDataList}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(handleResponse);

        const values = [fetchSystemPropertiesByCategory, fetchOtherSystemProperties, fetchDataListTypes];

        const cookies = new Cookies();
        const rememberMe = cookies.get('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');

        if (!secureStorage.getItem('accountID') && rememberMe) {
            const req = { userName: rememberMe };
            const fetchRememberMeAccess = fetch(`${state.common.serviceUrl}/AuthenticateAutoLogin`, { headers: headers, method: 'POST', body: JSON.stringify(req), credentials: "same-origin" })
                .then(handleResponse);

            values.push(fetchRememberMeAccess);
        }


        //call all methods and when got all results process them
        Promise.all(values)
            .then((responses) => {
                const myAccountSysProperties = responses[0];
                const otherSystemProperties = responses[1];
                const dataListTypes = responses[2];
                const accessToken = responses[3];

                const allSysProperties = myAccountSysProperties.concat(otherSystemProperties);

                const systemProperties = {};

                systemProperties.customerServicePhone = getSysPropValue(allSysProperties, 'CUSTOMER_SERVICE_PHONE');
                systemProperties.customerServiceHoursEN = getSysPropValue(allSysProperties, 'CUSTOMER_SERVICE_HOURS_EN');
                systemProperties.customerServiceHoursES = getSysPropValue(allSysProperties, 'CUSTOMER_SERVICE_HOURS_ES');
                systemProperties.customerServiceEmail = getSysPropValue(allSysProperties, 'CUSTOMER_SERVICE_EMAIL');

                systemProperties.messagingUtilityName = getSysPropValue(allSysProperties, 'MESSAGING_UTILITY_NAME');
                systemProperties.marketerBillingAddress = getSysPropValue(allSysProperties, 'MARKETER_BILLING_ADDRESS');
                systemProperties.marketerMailingAddress = getSysPropValue(allSysProperties, 'MARKETER_MAILING_ADDRESS');
                systemProperties.marketerFacebookUrl = getSysPropValue(allSysProperties, 'MARKETER_FACEBOOK_URL');
                systemProperties.marketerTwitterUrl = getSysPropValue(allSysProperties, 'MARKETER_TWITTER_URL');
                systemProperties.marketerLinkedinUrl = getSysPropValue(allSysProperties, 'MARKETER_LINKEDIN_URL');

                systemProperties.marketerTosUrl = getSysPropValue(allSysProperties, 'MARKETER_TOS_URL');
                systemProperties.externalConversionUrl = getSysPropValue(allSysProperties, 'MYACCT_EXTERNAL_CONVERSION_URL');

                systemProperties.myAcctPayByPhoneTextEN = getSysPropValue(allSysProperties, 'MYACCT_PAY_BY_PHONE_TEXT_EN');
                systemProperties.myAcctPayByPhoneTextES = getSysPropValue(allSysProperties, 'MYACCT_PAY_BY_PHONE_TEXT_ES');

                systemProperties.myAcctPayWithCashTextEN = getSysPropValue(allSysProperties, 'MYACCT_PAY_WITH_CASH_TEXT_EN');
                systemProperties.myAcctPayWithCashTextES = getSysPropValue(allSysProperties, 'MYACCT_PAY_WITH_CASH_TEXT_ES');

                systemProperties.myAcctPayByMailTextEN = getSysPropValue(allSysProperties, 'MYACCT_PAY_BY_MAIL_TEXT_EN');
                systemProperties.myAcctPayByMailTextES = getSysPropValue(allSysProperties, 'MYACCT_PAY_BY_MAIL_TEXT_ES');

                systemProperties.myAcctTranServiceAddLocationTitleEN = getSysPropValue(allSysProperties, 'MYACCOUNT_TRANSFEROFSERVICE_ADDLOCATION_TITLE_EN');
                systemProperties.myAcctTranServiceAddLocationTitleES = getSysPropValue(allSysProperties, 'MYACCOUNT_TRANSFEROFSERVICE_ADDLOCATION_TITLE_ES');
                systemProperties.myAcctTranServiceAddLocationDesEN = getSysPropValue(allSysProperties, 'MYACCT_TRANSFEROFSERVICE_ADDLOCATION_DES_EN');
                systemProperties.myAcctTranServiceAddLocationDesES = getSysPropValue(allSysProperties, 'MYACCT_TRANSFEROFSERVICE_ADDLOCATION_DES_ES');

                systemProperties.myAcctRenewalUrlRedirection = getSysPropValue(allSysProperties, 'MYACCT_RENEWAL_URL_REDIRECTION');

                systemProperties.myAcctMviFutureDaysAllowed = parseInt(getSysPropValue(allSysProperties, 'MYACCT_MVI_FUTURE_DAYS_ALLOWED', '0'));
                systemProperties.myAcctMvoFutureDaysAllowed = parseInt(getSysPropValue(allSysProperties, 'MYACCT_MVO_FUTURE_DAYS_ALLOWED', '0'));
                systemProperties.myAcctAutopayMinimumPayment = parseInt(getSysPropValue(allSysProperties, 'MYACCT_AUTOPAY_MINIMUM_PAYMENT', '0'));
                systemProperties.postpayPayExtensionMaxAnnualRequests = parseInt(getSysPropValue(allSysProperties, 'POSTPAY_PAY_EXTENSION_MAX_ANNUAL_REQUESTS', '0'));
                systemProperties.postpayPayExtensionMaxDays = parseInt(getSysPropValue(allSysProperties, 'POSTPAY_PAY_EXTENSION_MAX_DAYS', '0'));
                systemProperties.consumptionDecimalNumber = getSysPropValue(allSysProperties, 'BILL_CONSUMPTION_NUMBER_OF_DECIMALS');
                systemProperties.isEnrollmentAddOnEnabled = getSysPropValue(allSysProperties, 'ENROLLMENT_ADD_ON_CHARGES_ENABLED');
                systemProperties.myacctOtherAmountProcessingFee = parseFloat(getSysPropValue(allSysProperties, 'MYACCT_OTHER_AMOUNT_PROCESSING_FEE', '0'));

                systemProperties.myacctReferralTitle = getSysPropValue(allSysProperties, 'MYACCOUNT_REFERRAL_TITLE');
                systemProperties.myacctReferralDescription = getSysPropValue(allSysProperties, 'MYACCOUNT_REFERRAL_DESCRIPTION');
                systemProperties.myacctReferralLogo = getSysPropValue(allSysProperties, 'MYACCOUNT_REFERRAL_LOGO');
                systemProperties.isSlimCDEnabled = getSysPropValue(allSysProperties, 'ENABLE_SLIMCD_PAYMENTS').toLowerCase() === 'true';
                systemProperties.slimCDUsername = getSysPropValue(allSysProperties, 'PAYMENT_SLIMCD_USERNAME');
                systemProperties.paymentusCreditCardCharge = getSysPropValue(allSysProperties, 'MYACCOUNT_PAYMENTUS_CC_CHARGE_VALUE');
                systemProperties.paymentusECheckCharge = getSysPropValue(allSysProperties, 'MYACCOUNT_PAYMENTUS_ECHECK_CHARGE_VALUE');
                systemProperties.billDocumentGenerationChartSetting = getSysPropValue(allSysProperties, 'BILL_DOCUMENT_GENERATION_CHART_SETTINGS');
                systemProperties.reCaptchaKey = setRecaptchaKey(getSysPropValue(allSysProperties, 'MYACCOUNT_RECAPTCHA_KEY', undefined));

                let myAcctOtherPaymentTenderTypes = getSysPropValue(allSysProperties, 'MYACCT_OTHER_PAYMENT_TENDER_TYPES').split(',');
                let myAcctPaymentCardTypes = getSysPropValue(allSysProperties, 'MYACCT_PAYMENT_CARD_TYPES').split(',');
                let myAcctPaymentTenderTypes = getSysPropValue(allSysProperties, 'MYACCT_PAYMENT_POSTPAID_TENDER_TYPES').split(',');
                let myAcctPaymentPrepaidTenderTypes = getSysPropValue(allSysProperties, 'MYACCT_PAYMENT_PREPAID_TENDER_TYPES').split(',');
                let myAcctDisplayPaymentProvider = getSysPropValue(allSysProperties, 'MYACCT_DISPLAY_PAYMENT_PROVIDER', 0);

                let arrPayLogo = ['online-empty.png', 'online-authorize-net.png', 'online-chase.png', 'online-cove.png', 'online-cybersource.png', 'online-heart.png', 'online-icheck.png',
                    'online-nmi.png', 'online-paymenttech.png', 'online-paynearme.png', 'online-plugnpay.png', 'online-shift4.png', 'online-veri.png', 'online-stripe.png', 'online-paymentus.png'];

                let imageIndex = myAcctDisplayPaymentProvider !== '' ? parseInt(myAcctDisplayPaymentProvider) : 0;
                imageIndex = imageIndex >= 0 && imageIndex < arrPayLogo.length ? imageIndex : 0;

                systemProperties.myAcctDisplayPaymentProvider = { index: imageIndex };
                systemProperties.myAcctDisplayPaymentProvider['imageName'] = arrPayLogo[systemProperties.myAcctDisplayPaymentProvider.index];

                systemProperties.myAcctOtherPaymentTenderTypes = {
                    payByPhone: myAcctOtherPaymentTenderTypes.includes('1'),
                    payWithCash: myAcctOtherPaymentTenderTypes.includes('2'),
                    payByMail: myAcctOtherPaymentTenderTypes.includes('3')
                };

                systemProperties.paymentCardTypes = myAcctPaymentCardTypes;

                systemProperties.myAcctPaymentTenderTypes = {
                    creditCard: myAcctPaymentTenderTypes.includes('1'),
                    eCheck: myAcctPaymentTenderTypes.includes('2'),
                    cash: myAcctPaymentTenderTypes.includes('3'),
                    check: myAcctPaymentTenderTypes.includes('4'),
                    debitCard: myAcctPaymentTenderTypes.includes('5'),
                    eCheckNoPost: myAcctPaymentTenderTypes.includes('7'),
                    crypto: myAcctPaymentTenderTypes.includes('10'),

                    creditCardPrepaid: myAcctPaymentPrepaidTenderTypes.includes('1'),
                    eCheckPrepaid: myAcctPaymentPrepaidTenderTypes.includes('2'),
                    cashPrepaid: myAcctPaymentPrepaidTenderTypes.includes('3'),
                    checkPrepaid: myAcctPaymentPrepaidTenderTypes.includes('4'),
                    debitCardPrepaid: myAcctPaymentPrepaidTenderTypes.includes('5'),
                    eCheckNoPostPrepaid: myAcctPaymentPrepaidTenderTypes.includes('7'),
                    cryptoPrepaid: myAcctPaymentPrepaidTenderTypes.includes('10')
                };

                systemProperties.emailContactInfoRequired = getSysPropValue(allSysProperties, 'EMAIL_CONTACT_INFO_REQUIRED').toLowerCase() === 'true';
                systemProperties.smsContactInfoRequired = getSysPropValue(allSysProperties, 'SMS_CONTACT_INFO_REQUIRED').toLowerCase() === 'true';
                systemProperties.myAcctShowPayextension = getSysPropValue(allSysProperties, 'MYACCT_SHOW_PAYEXTENSION').toLowerCase() === 'true';
                systemProperties.myAcctShowPlansDocuments = getSysPropValue(allSysProperties, 'MYACCT_SHOW_PLANS_AND_DOCUMENTS').toLowerCase() === 'true';
                systemProperties.myAcctShowDailyIntervalReads = getSysPropValue(allSysProperties, 'MYACCT_ACTIVITY_SHOW_DAILY_INTERVAL_READS').toLowerCase() === 'true';
                systemProperties.myAcctHideEcheckForDisconnected = getSysPropValue(allSysProperties, 'MYACCT_HIDE_ECHECK_FOR_DISCONNECTED_ACCOUNT').toLowerCase() === 'true';
                systemProperties.myAcctShowMvo = getSysPropValue(allSysProperties, 'MYACCT_SHOW_MVO_SERVICE_REQUEST').toLowerCase() === 'true';
                systemProperties.myAcctShowTransferOfService = getSysPropValue(allSysProperties, 'MYACCT_SHOW_TRANSFER_OF_SERVICE_REQUEST').toLowerCase() === 'true';
                systemProperties.myAcctShowAddLocation = getSysPropValue(allSysProperties, 'MYACCT_SHOW_ADD_LOCATION_REQUEST').toLowerCase() === 'true';
                systemProperties.myAcctShowBackflowInspection = getSysPropValue(allSysProperties, 'MYACCT_SHOW_BACKFLOW_INSPECTION').toLowerCase() === 'true';
                systemProperties.membershipEnabled = getSysPropValue(allSysProperties, 'MEMBERSHIP_ENABLED').toLowerCase() === 'true';
                systemProperties.displayMemberProgram = getSysPropValue(allSysProperties, 'MYACCOUNT_DISPLAY_MEMBERPROGRAMS').toLowerCase() === 'true';
                systemProperties.myAcctHoldEnrollmentTransferServiceAddLocation = getSysPropValue(allSysProperties, 'MYACCT_TRANSFERSERVICE_ADDLOCATION_HOLDENROLLMENT').toLowerCase() === 'true';
                systemProperties.myAcctAllowMailAddressEdit = getSysPropValue(allSysProperties, 'MYACCT_ALLOW_MAIL_ADDRESS_EDIT').toLowerCase() === 'true';
                systemProperties.myAcctShowLanguagePreference = getSysPropValue(allSysProperties, 'MYACCT_SHOW_LANGUAGE_PREFERENCE').toLowerCase() === 'true';

                if (dataListTypes && dataListTypes.listItems && dataListTypes.listItems.length) {
                    systemProperties.bankAccountTypes = dataListTypes.listItems[0].listData;
                    systemProperties.creditCards = dataListTypes.listItems[1].listData;
                    systemProperties.meterStatus = dataListTypes.listItems[2].listData;
                }

                systemProperties.isLoaded = true;

                dispatch({ type: 'SET_SYSTEM_PROPERTIES', systemProperties: systemProperties });

                if (accessToken && accessToken.isAuthenticated && accessToken.accounts && accessToken.accounts.length > 0) {

                    secureStorage.setItem('accountID', accessToken.accounts[0].accountID);
                    secureStorage.setItem('accounts', accessToken.accounts);
                    secureStorage.setItem('privateToken', 'bearer ' + accessToken.token);
                    history.push('/myaccount/dashboard');

                } else {
                    cookies.remove('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');
                }

            })
            .catch(function (error) {
                console.error(error.message);
                cookies.remove('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');
                dispatch({ type: 'SET_COMMON_ERROR', errorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_COMMON_START' });

    } catch (e) {
        console.log(e);
    }
};

const nonWorkingDaysNStates = function (dispatch, getState, data) {

    let state = getState();
    const companyID = state.common.companyID;

    headers.Authorization = state.common.publicToken;

    var values = [];

    if (data.statesData && state.common.statesList.length === 0) {
        const requestStatesList = { languageID: state.common.langID };
        var queryStringStates = Object.keys(requestStatesList).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestStatesList[key])).join('&');
        const fetchStatesList = fetch(`${state.common.serviceUrl}/StatesList?${queryStringStates}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(handleResponse);
        values.push(fetchStatesList);
    }

    if (data.nonWorkingDaysData) {
        const requestNonWorkingDays = { distributionServiceProviderID: data.nonWorkingDaysData.distributionServiceProviderID, workingDaysGroupID: data.nonWorkingDaysData.workingDaysGroupID, companyID, languageID: state.common.langID };
        var queryStringWDays = Object.keys(requestNonWorkingDays).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestNonWorkingDays[key])).join('&');

        var nonWorkingDays = state.common.nonWorkingDaysByTdu.filter(x => x.distributionServiceProviderID === data.nonWorkingDaysData.distributionServiceProviderID && data.nonWorkingDaysData.workingDaysGroupID === x.workingDaysGroupID);
        if (data.nonWorkingDaysData.distributionServiceProviderID > 0 && nonWorkingDays.length === 0) {
            const fetchNonWorkingDays = fetch(`${state.common.serviceUrl}/ServiceProviderNonWorkingDaysAndHolidays?${queryStringWDays}`, { headers: headers, method: 'GET', credentials: "same-origin" })
                .then(handleResponse);
            values.push(fetchNonWorkingDays);
        }
    }

    if (values.length > 0) {
        //call all methods and when got all results process them
        Promise.all(values)
            .then((responses) => {
                const nonWorkingDays = responses.find(x => Array.isArray(x) && x.find(f => f.value === undefined));
                const statesResp = responses.find(x => Array.isArray(x) && x.find(f => f.value !== undefined));

                var nonWorkingDaysByTdu = state.common.nonWorkingDaysByTdu;

                if (nonWorkingDays) {
                    var ca = {};
                    ca.distributionServiceProviderID = data.nonWorkingDaysData.distributionServiceProviderID;
                    ca.workingDaysGroupID = data.nonWorkingDaysData.workingDaysGroupID;
                    ca.nonWorkingDays = nonWorkingDays.map(x => Date.parse(x));
                    nonWorkingDaysByTdu.push(ca);
                }

                var statesList = state.common.statesList;

                if (statesResp) {
                    statesList = statesResp;
                }

                dispatch({ type: 'SET_TDU_NON_WORKING_DAYS&STATES_LIST', nonWorkingDaysByTdu, statesList });
            })
            .catch(function (error) {
                console.error(error.message);
                dispatch({ type: 'SET_COMMON_ERROR', errorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_COMMON_START' });
    }
};

const upsAddressCheck = function (dispatch, getState, request) {
    const state = getState();
    headers.Authorization = state.common.publicToken;

    request['languageID'] = state.common.langID;

    var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

    //TODO: review webmethod
    fetch(`${state.common.serviceUrl}/UspsAddress?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(handleResponse)
        .then(data => {

            if (data) {
                if (data.addressFound) {
                    var uspsAddress = data;
                    uspsAddress['status'] = state.common.constants.uspsAddressStatus.FOUND;
                    dispatch({ type: 'RESPONSE_USPS_ADDRESS', uspsAddress: data });
                } else {
                    dispatch({ type: 'RESPONSE_USPS_ADDRESS', uspsAddress: { status: state.common.constants.uspsAddressStatus.NOT_FOUND } });
                }

            } else {
                dispatch({ type: 'SET_COMMON_ERROR', errorMessage: T.translate('quickPay.noAccountFound') });
            }

        })
        .catch(function (error) {

            dispatch({ type: 'SET_COMMON_ERROR', errorMessage: error.message });
        });

    dispatch({ type: 'RESET_COMMON_STATE_VALUE', resetInfo: { uspsAddress: { status: state.common.constants.uspsAddressStatus.PENDING } } });
    dispatch({ type: 'REQUEST_COMMON_START' });
};

const getSysPropValue = function (systemProperties, sysPropertyName, defaultValue = '') {
    const sysProperty = systemProperties.filter(x => x.propertyName === sysPropertyName)[0];
    if (sysProperty && sysProperty.propertyValue && sysProperty.propertyValue.trim() !== '') {
        return sysProperty.propertyValue;
    }

    return defaultValue;
};

const setLanguage = function (dispatch, lang) {

    //if no language set - try to get it from the browser
    if (!lang) {

        try {
            lang = window.navigator.language;
            lang = lang ? lang.substring(0, 2) : 'en';
        } catch (e) {
            console.log(e);
        }

        lang = lang === 'en' || lang === 'es' ? lang : 'en';
    }

    T.setTexts(require(`../resources/${process.env.REACT_APP_CLIENT}/${lang}.json`));
    Moment.locale(lang);

    let langID = lang === 'en' ? 1 : 2;

    setDefaultLocale(lang);

    dispatch({ type: 'SET_LANGUAGE', lang: lang, langID: langID });

};

const toQueryString = function (obj, prefix) {
    let str = [], k, v;
    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        } // skip things from the prototype
        if (~p.indexOf('[')) {
            k = prefix ? prefix + "[" + p.substring(0, p.indexOf('[')) + "]" + p.substring(p.indexOf('[')) : p;
            // only put whatever is before the bracket into new brackets; append the rest
        }
        else {
            k = prefix ? prefix + "[" + p + "]" : p;
        }
        v = obj[p];
        if (typeof v === "object") {
            str.push(toQueryString(v, k));
        }
        else {
            str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
};

export function getPersonID(state, accountID) {
    let accountFromStorage = secureStorage.getItem('accounts');
    let accountsToFilter = accountFromStorage && accountFromStorage != null ? accountFromStorage : state.common.accounts;
    if (!accountsToFilter)
        return 0;

    let personID = 0;
    accountsToFilter.map(a => {
        if (a.accountID === accountID && (a.persons && a.persons.length > 0)) {
            personID = a.persons[0].personID;
            return personID;
        }
    });

    return personID;
}

export function getRecaptchaKey() {
    const cookies = new Cookies();
    let recaptchaKey = cookies.get(RECAPTCHA_COOKIE_KEY);

    if (recaptchaKey === undefined)
        recaptchaKey = RECAPTCHA_COOKIE_KEY;

    return recaptchaKey;
}

const setRecaptchaKey = function (recaptchaKey) {
    const cookies = new Cookies();

    try {
        cookies.set(RECAPTCHA_COOKIE_KEY, recaptchaKey, { path: '/', sameSite: 'strict' });
    } catch (error) {
        console.error('Failed to set Recaptcha key:', error);
    }

    return recaptchaKey;
};

export function formatDate(date, format = 'MMM DD, YYYY') {

    let value = '';

    try {
        if (date && Moment(date) > Moment("0001-01-01")) {
            value = Moment(date).format(format);
        }
    } catch (e) {
        console.log(e);
    }

    return value;
}

export function formatDecimal(amount, decimalCount = 2, decimal = ".", thousands = ",") {

    let value = '0.00';

    if (decimalCount === 0) {
        value = '0';
    }

    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e);
    }

    return value;
}

export function addDaysToDate(date, daysNumber) {
    var dateObject = typeof date === 'string' ? new Date(date) : date;
    dateObject.setDate(dateObject.getDate() + daysNumber);
    return dateObject;
}

export function addMonthsToDate(date, monthsNumber) {
    var dateObject = typeof date === 'string' ? new Date(date) : date;
    dateObject.setMonth(dateObject.getMonth() + monthsNumber);
    return dateObject;
}

export function addYearsToDate(date, yearsNumber) {
    var dateObject = typeof date === 'string' ? new Date(date) : date;
    dateObject.setFullYear(dateObject.getFullYear() + yearsNumber);
    return dateObject;
}

export function getArrayFromBase64(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export function downloadPDF(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
}

export function openPDF(byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var url = window.URL.createObjectURL(blob);
    window.open(url);
    window.URL.revokeObjectURL(url);
}

export function objectHasProperties(object) {
    for (var prop in object) {
        if (object.hasOwnProperty(prop)) {
            return true;
        }
    }
    return false;
}

export function textWrap(originalText, separator = '\\n') {
    var result = [];
    var textLines = originalText.split(separator);

    for (var i = 0; i < textLines.length; i++) {
        result.push(textLines[i]);

        if (result.length < textLines.length) {
            result.push(<br key={i} />);
        }
    }

    return result;
}

export function getCardInfo(cardNumber, onlyNumbers = true) {

    const defaultInfo = {
        name: "none",
        type: "",
        regEx: "",
        mask: "9999-9999-9999-9999"
    };

    if (onlyNumbers && isNaN(cardNumber))
        return defaultInfo;

    var cardInfo = creditInfoList.find(x => {
       return x.literalregEx ?
            x.regEx.test(cardNumber.replaceAll('_', '0').replaceAll('-', '')) :
            cardNumber.match(new RegExp(x.regEx)) !== null;
    });

    if (cardInfo) {
        return cardInfo;
    }
    return defaultInfo;
}

export function getCardType(number) {
    var cardInfo = getCardInfo(number);
    return cardInfo.type;
}

export function hasMinWordsAmount(field, e) {
    var isValid = true;

    var value = e ? e.target.value : field.value;

    if (field.minTextWords) {
        var words = value.trim().split(' ');
        if (words.length >= field.minTextWords) {
            field.isRequiredError = false;
        } else {
            field.isRequiredError = true;
            value = false;
        }
    }

    return isValid;
}

export function isValidCreditCardNumber(field, e) {
    var isValid = true;
    var value = e ? e.target.value : field.value;

    if (value.trim() === '' || hasCardMaskError(value)) {
        field.isRequiredError = true;
        isValid = false;
    } else {
        field.isRequiredError = false;
        isValid = true;
    }

    return isValid;
}

export function hasCardMaskError(cardNumber) {
    var cardInfo = getCardInfo(cardNumber, false);

    if (cardInfo.minLength && cardInfo.maxLength) {
        //in some cases like visa the mask can allow up to 16 digits, but they actually support between 13 and 16 digits
        var noMaskedValue = cardNumber.replaceAll('_', '').replaceAll('-', '');
        if (noMaskedValue.length < cardInfo.minLength || noMaskedValue.length > cardInfo.maxLength) {
            return true;
        } else {
            return false;
        }
    }
    
    if (cardNumber.includes('_')) {
        return true;
    } else {
        return false;
    }
}

export function isValidCreditCardExpDate(field, e) {
    var value = e ? e.target.value : field.value;

    if (value.includes('_')) {
        field.isRequiredError = true;
        field.isInvalidDateFormatError = false;
        field.isNotMinDateError = false;
    } else {
        var currentDate = new Date();
        var minYearPrefix = currentDate.getFullYear().toString().slice(-4, 2)

        var cardMmYyDate = value.split("/");
        var cardExpMont = cardMmYyDate[0];
        var cardExpYear = minYearPrefix + cardMmYyDate[1];//Adjusting to 4 digits year format

        //getting first day of month
        var cardExpirationDate = new Date(cardExpYear + "/" + cardExpMont + "/01");

        if (isNaN(cardExpirationDate)) {
            field.isInvalidDateFormatError = true;
        } else {
            field.isInvalidDateFormatError = false;
            //getting last day of month
            cardExpirationDate = new Date(cardExpirationDate.getFullYear(), cardExpirationDate.getMonth() + 1, 0);
        }

        if (currentDate > cardExpirationDate) {
            field.isNotMinDateError = true;
        } else {
            field.isNotMinDateError = false;
        }

        field.isRequiredError = field.isInvalidDateFormatError || field.isNotMinDateError;
    }

    return !field.isRequiredError;
}

export function isValidCvv(field, e) {
    var isValid = true;
    var value = e ? e.target.value : field.value;

    if (!/^[0-9]{3,4}$/.test(value)) {
        field.isRequiredError = true;
        field.isInvalidCvv = value !== '';
        isValid = false;
    } else {
        field.isRequiredError = false;
        field.isInvalidCvv = false;
        isValid = true;
    }

    return isValid;
}

export function isValidZipCode(field, e, props) {
    var isValid = true;
    var value = e ? e.target.value : field.value;

    if (value.match(new RegExp(props.zipCodeRegEx)) === null) {
        field.isRequiredError = true;
        field.isInvalidZipCode = value !== '';
        isValid = false;
    } else {
        field.isRequiredError = false;
        field.isInvalidZipCode = false;
        isValid = true;
    }

    return isValid;
}



export function getI4GoCardType(cardTypeID) {

    if (isNaN(cardTypeID))
        return "";

    if (cardTypeID === '1')
        return "VS";

    if (cardTypeID === '2')
        return "MC";

    if (cardTypeID === '3')
        return "NS";

    if (cardTypeID === '4')
        return "AX";

    return "";
}

export function getI4GoCardEntryRequest(accessblock, cardTypeID, cardnumber, expirationmonth, expirationyear, ccv) {
    return {
        fuseaction: 'api.jsonPostCardEntry',
        i4go_accessblock: accessblock,
        i4go_cardtype: getI4GoCardType(cardTypeID),
        i4go_cardnumber: cardnumber,
        i4go_expirationmonth: expirationmonth,
        i4go_expirationyear: expirationyear,
        i4go_cvv2code: ccv
    };
}

export function checkPasswordRules(password) {
    var pwdValidator = new passwordValidator();

    pwdValidator.is().min(8)// Minimum length 8
        .has().uppercase()// Must have uppercase letters
        //.has().lowercase()// Must have lowercase letters
        .has().digits() // Must have digits
        .has().not().spaces() //Must not have spaces
        .has().not(/[;<>\\{}[\]+=?&,:'"`]/g); //Must not have specified characters

    return pwdValidator.validate(password);
}

export function getNumericInputText(numberTxt) {
    //filtering only numeric caracters and dot
    numberTxt = numberTxt.replace(/[^0-9.]|\.(?=.*\.)/g, "").trim();

    if (numberTxt === '.') {
        numberTxt = '0.';
    }

    if (!isNaN(numberTxt)) {
        //filtering 2 decimal places if present
        var result = numberTxt.match(/^-?\d+(?:\.\d{0,2})?/);

        if (result && result.length > 0) {
            numberTxt = result[0];
        }
    }
    return numberTxt;
}

export function getLocationStatusGroup(serviceLocationStatusID, camelCase) {
    var noServiceActivated = [21, 24, 16, 5];

    if (noServiceActivated.includes(serviceLocationStatusID)) {
        return camelCase ? 'noServiceActivated' : 'no-service-activated';
    }

    var problem = [22];

    if (problem.includes(serviceLocationStatusID)) {
        return 'problem';
    }

    var activating = [1, 2, 3, 4, 6, 7, 9, 23, 25, 26];

    if (activating.includes(serviceLocationStatusID)) {
        return 'activating';
    }

    var active = [8, 12, 13, 15, 18, 20];

    if (active.includes(serviceLocationStatusID)) {
        return 'active';
    }

    var pendingDisconnect = [10];

    if (pendingDisconnect.includes(serviceLocationStatusID)) {
        return camelCase ? 'pendingDisconnect' : 'pending-disconnect';
    }

    var disconnected = [11];

    if (disconnected.includes(serviceLocationStatusID)) {
        return 'disconnected';
    }

    var switchedAway = [19];

    if (switchedAway.includes(serviceLocationStatusID)) {
        return camelCase ? 'switchedAway' : 'switched-away';
    }

    var movedOut = [14];

    if (movedOut.includes(serviceLocationStatusID)) {
        return camelCase ? 'movedOut' : 'moved-out';
    }

    return 'none';
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case 'REQUEST_COMMON_INIT':
            return { ...state, commonErrorMessage: '', commonLoading: true };
        case 'REQUEST_COMMON_START':
            return { ...state, commonErrorMessage: '', commonLoading: true };
        case 'RESPONSE_COMMON_INIT':
            return { ...state, initiated: action.initiated, publicToken: action.publicToken, commonLoading: false };
        case 'SET_COMMON_ERROR':
            return { ...state, commonLoading: false, commonErrorMessage: action.errorMessage };
        case 'SET_LANGUAGE':
            return { ...state, lang: action.lang, langID: action.langID };
        case 'SET_ACCOUNT':
            return { ...state, commonLoading: false, account: action.account, accountID: action.account.accountID, accountServicePointID: action.accountServicePointID, servicePointID: action.servicePointID, accounts: action.accounts };
        case 'SET_ACCOUNT_SERVICE_POINT':
            return { ...state, accountServicePointID: action.accountServicePointID, servicePointID: action.servicePointID };
        case 'SET_TDU_NON_WORKING_DAYS&STATES_LIST':
            return { ...state, nonWorkingDaysByTdu: action.nonWorkingDaysByTdu, statesList: action.statesList, commonLoading: false };
        case 'RESPONSE_USPS_ADDRESS':
            return { ...state, uspsAddress: action.uspsAddress, commonLoading: false };
        case 'CLEAR_COMMON_STATE':
            return { ...state, accountID: 0, account: {} };
        case 'RESET_COMMON_STATE_VALUE':
            return { ...state, ...action.resetInfo };
        case 'SET_SYSTEM_PROPERTIES':
            return { ...state, commonLoading: false, systemProperties: action.systemProperties };
        case 'SET_IP_ADDRESS':
            return { ...state, ip: action.ip };
        case 'SET_COMMON_CONFIGURATION':
            return { ...state, ...action.config };
        default:
            return state;
    }
};
