import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import ExternalLink from '../shared/ExternalLink';
import PaymentSidebar from './PaymentSidebar';
import LoadingOverlay from '../shared/LoadingOverlay';
import LoadingButton from '../shared/LoadingButton';
import MessageError from '../shared/MessageError';
import ConfirmModal from '../shared/ConfirmModal';
import validator from 'validator';
import T from 'i18n-react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { PaymentAccountOption } from './PaymentAccountOption';
import { PaymentAccountSingleValue } from './PaymentAccountSingleValue';
import SuggestedPayment from '../shared/SuggestedPayment';

const Scroll = require('react-scroll');
const card = require(`../../images/${process.env.REACT_APP_CLIENT}/card.png`);
const paymentIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/payment-icon.png`);

const Recaptcha = require('react-recaptcha');

class PayCreditCard extends Component {

    constructor(props) {
        super(props);   

        this.state = {
            valid_captcha: false,
            anotherPaymentMethod: true,
            tenderTypeID: 1,
            amount: '',
            cardNumberValue: '',
            expiration: '',
            ccv: '',
            nameOnAccount: '',
            cardZipCode: '',
            open: false,
            fields: [
                { name: 'nameOnAccount', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, minTextWords: 2, onValidating: CommonStore.hasMinWordsAmount },
                { name: 'cardNumberValue', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, onValidating: CommonStore.isValidCreditCardNumber },
                { name: 'expiration', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, onValidating: CommonStore.isValidCreditCardExpDate },
                { name: 'ccv', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, onValidating: CommonStore.isValidCvv },
                { name: 'cardZipCode', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, onValidating: CommonStore.isValidZipCode },
                { name: 'amount', isRequired: true, isNumeric: true, minValue: 0 }

            ],
            //CV ZD14560 2020/03/26 adding this properties to handle client stripe token generation
            cardZipCodeStripe: '',
            paymentStripePK: this.props.getPaymentStripePK(),
            stripe: {},
            elements: {},
            cardNumberElement: {},
            cardExpiryElement: {},
            cardCvcElement: {},
            suggestedPaymentObject: {},
            totalPayment: 0,
            accountPaymentDistributions: [],
            slimCD: {},
            paymentChargeFee: {}
        };

        //CV ZD14560 2020/03/26 adding this validation if stripe PK is enable then load the stripe script, and build the respective objects
        if (this.state.paymentStripePK) {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/";
            script.async = true;
            script.onload = () => {
                //Creating the main stripe objects
                this.setState({ stripe: window.Stripe(this.state.paymentStripePK) });
                this.setState({ elements: this.state.stripe.elements() });
                //Create cardnumber element (this is the main element)
                this.setState({ cardNumberElement: this.state.elements.create('cardNumber', { placeholder: ' ' }) });
                this.state.cardNumberElement.mount('#txtCreditCardStripe');
                //Create cardexpiry element
                this.setState({ cardExpiryElement: this.state.elements.create('cardExpiry', { placeholder: ' ' }) });
                this.state.cardExpiryElement.mount('#txtExpireStripe');
                //Create cardcvc element
                this.setState({ cardCvcElement: this.state.elements.create('cardCvc', { placeholder: ' ' }) });
                this.state.cardCvcElement.mount('#txtCcvStripe');
            };
            document.body.appendChild(script);
        }

        if (this.props.systemProperties.isSlimCDEnabled) {
            const scriptSlimCD = document.createElement("script");
            scriptSlimCD.src = "https://stats.slimcd.com/soft/json/slimcd.js";
            scriptSlimCD.async = true;            
            scriptSlimCD.onload = () => {
                this.setState({ slimCD: window["SlimCD"] });
            };
            document.body.appendChild(scriptSlimCD);            
        }
    }

    verifyCallback = (response) => {
        if (response) {

            this.setState({ valid_captcha: true });

            if (!this.props.enableAuthenticatePaymentInfo) {
                this.props.authorizeAccountPaymentByPass();
            }
        }
        else {
            this.setState({ valid_captcha: false });
        }
    }

    expiredAuthPaymentRecaptcha = () => {
        this.setState({ valid_captcha: false });
        this.props.authorizeAccountPaymentExpired();
    }

    verifyCardInfo = () => {
        
        if (!this.props.accountPayCreditCardPaymentAccount.paymentAccountID && this.state.paymentStripePK) {
            var options = {
                address_zip: this.state.cardZipCodeStripe
            };
        
            this.state.stripe.createToken(this.state.cardNumberElement, options).then((result) => {
                if (result.error) {
                    
                    this.props.setPaymentError(result.error.message);
                } else {
                    this.setState({ paymentToken: result.token.id });
                    this.setState({ cardNumberValue: '************' + result.token.card.last4 });
                    this.setState({ expireMonth: result.token.card.exp_month });
                    this.setState({ expireYear: result.token.card.exp_year });
                    this.setState({ cardZipCode: this.state.cardZipCodeStripe });
                    this.setState({ ccv: 123 });
                    this.props.authorizeAccountPayment(this.state);
                }
            });

        }
        else {
            if (this.isFormValid()) {
                this.props.authorizeAccountPayment(this.state);
            }
        }    
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('accountPay.title');

        if (this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
            this.props.getSuggestedPaymentObject();

            let state = this.state;
            var field = state.fields[5];
            var isPrepay = this.props.account.accountBillingTypeID === 1;
            field.minValue = isPrepay ? this.props.systemProperties.myAcctAutopayMinimumPayment : 0;
            this.setState({ fields: state.fields });
        }
        this.props.getI4GoAuthorizeClient();
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
            this.props.getSuggestedPaymentObject();

            let state = this.state;
            var field = state.fields[5];
            var isPrepay = this.props.account.accountBillingTypeID === 1;
            field.minValue = isPrepay ? this.props.systemProperties.myAcctAutopayMinimumPayment : 0;
            this.setState({ fields: state.fields });    
        }
        this.setState({ suggestedPaymentObject: nextProps.suggestedPaymentObject });

        if (nextProps.systemProperties.isLoaded) {
            this.setState({ paymentChargeFee: this.getPaymentChargeFeeObj(nextProps.systemProperties.paymentusCreditCardCharge) });
        }
    }

    onChange = (e) => {
        let fields = this.state.fields.filter(x => x.name === e.target.name);
        if (fields && fields.length === 1) {
            let field = fields[0];
            var idx = this.state.fields.indexOf(field);

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }

            if (field.isNumeric && e.target.value) {
                e.target.value = CommonStore.getNumericInputText(e.target.value);
            }

            if (field.isNumeric && e.target.value) {
                if (validator.isDecimal(e.target.value)) {
                    field.isNumericError = false;
                }

                if (field.minValue && parseFloat(e.target.value) >= field.minValue) {
                    field.isMinimumAmountError = false;
                }
            }

            if (field.onValidating) {
                field.onValidating(field, e, this.props);
            }

            fields = this.state.fields;
            fields[idx] = field;

            this.setState({ fields });
        }

        this.setState({ [e.target.name]: e.target.value });
    }    

    onTokenizeCreditDetailsClick = (e) => {
        this.setState({ tokenizeCreditDetails: !this.state.tokenizeCreditDetails });
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;

        if (!state.anotherPaymentMethod && !this.props.accountPayCreditCardPaymentAccount) {
            return false;
        }

        this.state.fields.map((field) => {

            if (field.conditionField && field.conditionValue === state[field.conditionField]) {
                field.value = this.state[field.name];
                field.isRequiredError = false;

                if (field.isRequired && !field.value) {
                    field.isRequiredError = true;
                    valid = false;
                }

                if (field.isNumeric && field.value) {
                    if (!validator.isDecimal(field.value)) {
                        field.isNumericError = true;
                        valid = false;
                    }

                    if (field.minValue && parseFloat(field.value) < field.minValue) {
                        field.isMinimumAmountError = true;
                        valid = false;
                    }
                }

                if (field.onValidating) {
                    valid = valid && field.onValidating(field, undefined, this.props);
                }
            }

        });

        this.setState(state);

        if (valid) {
            this.props.setPaymentError('');
        } else {
            this.props.setPaymentError(T.translate("common.thereAreMissingFields"));
        }

        return valid;
    }

    pay = () => {
        //CV ZD14560 2020/03/26 adding this validation if stripe PK is enable, and no already existend payment account selected, then the token will be generated here
        if (!this.props.accountPayCreditCardPaymentAccount.paymentAccountID && this.state.paymentStripePK) {
            var options = {
                address_zip: this.state.cardZipCodeStripe
            };
            //Create stripe token for this card, after that it is submited to the backend among with the other card information not sensitive
            this.state.stripe.createToken(this.state.cardNumberElement, options).then((result) => {
                if (result.error) {
                    // Inform the customer that there was an error generating the token.
                    this.props.setPaymentError(result.error.message);
                } else {
                    this.setState({ paymentToken: result.token.id });
                    this.setState({ cardNumberValue: '************' + result.token.card.last4 });
                    this.setState({ expireMonth: result.token.card.exp_month });
                    this.setState({ expireYear: result.token.card.exp_year });
                    this.setState({ cardZipCode: this.state.cardZipCodeStripe });
                    this.setState({ ccv: 123 });
                    this.postAccountPay();
                }
            });

        }
        else if (!this.props.accountPayCreditCardPaymentAccount.paymentAccountID && this.props.systemProperties.isSlimCDEnabled) {
            let processor = this.state.slimCD;
            let expireMonth = 0;
            let expireYear = 0;
            let firstName = "";
            let lastName = "";
            let props = this.props;
            let state = this.state;

            if (this.state.expiration.split('/').length === 2) {
                expireMonth = this.state.expiration.split('/')[0];
                expireYear = this.state.expiration.split('/')[1];
            }
            if (this.state.nameOnAccount.split(' ').length === 1) {
                firstName = this.state.nameOnAccount.split(' ')[0];
            }
            if (this.state.nameOnAccount.split(' ').length >= 2) {
                firstName = this.state.nameOnAccount.split(' ')[0];
                lastName = this.state.nameOnAccount.split(' ')[1];
            }
           
            processor.Transact.ProcessTransaction({
                "username": props.systemProperties.slimCDUsername,
                "transtype": "LOAD",
                "amount": "0.00",
                "cardnumber": this.state.cardNumberValue,
                "expmonth": expireMonth,
                "expyear": expireYear,
                "cvv2": this.state.ccv,
                "first_name": firstName,
                "last_name": lastName
            },  function (reply) {         
                    if (reply.datablock != undefined && reply.datablock.gateid != undefined)
                        if (reply.response == "Success") {
                            state.public_token = reply.datablock.gateid;
                            props.postAccountPay(state);
                        }
                        else {
                            alert('There was a problem processing the payment:\r\n' + reply.description);
                        }
            });
        }
        else
        {
            if (this.isFormValid()) {

                let amount = 0;

                if (this.props.showPaymentAmountWarning && this.props.account.accountBillingTypeID === 2 && amount > this.props.account.totalAmountDue) {
                    this.setState({ open: true });
                } else {
                    this.postAccountPay();
                }
            }
        }        
    }

    postAccountPay = () => {
        this.setState({ open: false });
        this.props.postAccountPay(this.state);
    }

    goToDashboard = (e) => {
        this.props.toggleAccountPaymentSuccessModal();
        this.props.setAccount();
        this.props.history.push('/myaccount/dashboard');
    }

    handleChange = (selectedOption) => {
        this.props.changeCreditCardPaymentAccount(selectedOption);
        this.setState({ anotherPaymentMethod: false });
    }

    handleClick = () => {
        this.props.changeCreditCardPaymentAccount({});
        this.setState({ anotherPaymentMethod: true });
    }

    open = () => {
        this.setState({ open: true });
    }

    close = () => {
        this.setState({ open: false });
    }

    handleTotalAmountCalculation = (totalPaymentAmount, accountPaymentDistributions) => {
        this.setState({ totalPayment: totalPaymentAmount }, function () {
            this.setState({ accountPaymentDistributions: accountPaymentDistributions });
        });
    }

    getExpirationDateError = (field) => {
        if (field.isRequiredError) {
            if (field.isNotMinDateError) {
                return 'accountPay.notMinimumDate';
            }
            else {
                if (field.isInvalidDateFormatError) {
                    return 'accountPay.invalidDateFormat';
                }
            }
            return 'common.thisFieldIsRequired';
        }

        return '';
    }

    getCvvError = (field) => {
        if (field.isRequiredError) {
            if (field.isInvalidCvv) {
                return 'accountPay.invalidCvv';
            }
            return 'common.thisFieldIsRequired';
        }

        return '';
    }

    getZipCodeError = (field) => {
        if (field.isRequiredError) {
            if (field.isInvalidZipCode) {
                return 'accountPay.invalidZipCode';
            }
            return 'common.thisFieldIsRequired';
        }

        return '';
    }

    getCreditCardMask = (cardNumber) => {
        var cardInfo = CommonStore.getCardInfo(cardNumber, false);        
        return cardInfo.mask;
    }

    getPaymentChargeFeeObj = (chargeFeeFromInit) => {
        let paymentChargeFee = {
            paymentMethod: T.translate('suggestedPayment.paymentsIncursCreditCard'),
            chargeFee: chargeFeeFromInit
        }

        return paymentChargeFee;
    }

    render() {
        return (
            <div className="payment-section">

                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.paymentLoading || this.props.authorizeAccountPaymentLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('accountPay.pageTitle')}</div>
                            <p className="lead text-dark">{T.translate('accountPay.welcomePaymentSystem', this.props.systemProperties)}</p>
                        </div>
                        <div className="diverter" />                        

                        <h6 className="card-title">{T.translate('accountPay.infoAsOf')} {CommonStore.formatDate(new Date())}</h6>

                        <div className="card bl-blue mb-4 mb-sm-5">
                            <div className="card-body pay-card">
                                <img src={card} alt="card" />
                                {this.props.account.accountBillingTypeID === 1 &&
                                    <ul>
                                        <li>
                                            <span>{T.translate('accountPay.accountNumber')}</span>
                                            <strong>{this.props.account.accountNumber}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.customerName')}</span>
                                            <strong>{this.props.account.primaryPersonName}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.amountBelowThreshold')}</span>
                                            <strong>${CommonStore.formatDecimal(this.props.account.disconnectThreshold)}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.availableBalance')}</span>
                                            <strong className={this.props.account.availableBalance > 0 ? "text-success" : "text-danger"}>${CommonStore.formatDecimal(this.props.account.availableBalance)} {this.props.account.availableBalance > 0 && <span className="text-success font-weight-bold"> {T.translate('dashboard.credit')}</span>}</strong> 
                                        </li>
                                    </ul>
                                }

                                {this.props.account.accountBillingTypeID !== 1 &&
                                    <ul>
                                        <li>
                                            <span>{T.translate('accountPay.accountNumber')}</span>
                                            <strong>{this.props.account.accountNumber}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.customerName')}</span>
                                            <strong>{this.props.account.primaryPersonName}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.pastDueCharges')}</span>
                                            <strong>${CommonStore.formatDecimal(this.props.account.pastDueAmount)}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.totalAccountBalance')}</span>
                                            <strong className={this.props.account.accountBalance < 0 ? "text-danger" : "text-success"}>${CommonStore.formatDecimal(this.props.invertBalance ? Math.abs(this.props.account.accountBalance) : this.props.account.accountBalance)} {this.props.account.accountBalance > 0 && <span className="text-success font-weight-bold"> {T.translate('dashboard.credit')}</span>}</strong> 
                                        </li>
                                    </ul>
                                }

                            </div>
                        </div>

                        <h6 className="card-title mb-4">{T.translate('accountPay.paymentAmount')}</h6>

                        <SuggestedPayment suggestedPaymentObject={this.state.suggestedPaymentObject}
                                          onFinishTotalAmountCalculation={this.handleTotalAmountCalculation}
                                          paymentChargeFee={this.state.paymentChargeFee} />

                        <div className="custom-control custom-checkbox credit-card-checkbox-outer checked">
                            <div className="credit-card-paybox">
                                <div className="row" style={{ marginTop: '20px' }}>

                                    <div className="col-sm-6">
                                        <h6 className="card-title">{T.translate('accountPay.useStoredPaymentMethod')}</h6>
                                    </div>
                                    <div className="col-sm-6 text-left text-sm-right">
                                        <NavLink to="/myaccount/paymentmethods" className="payment-link mb-3 d-inline-block">
                                            <img src={paymentIcon} alt="payment" /><span>{T.translate('autopay.addPaymentMethod')}</span>
                                        </NavLink>
                                        &nbsp;&nbsp;
                                        {this.props.accountPayCreditCardPaymentAccount.paymentAccountID && <a href="javascript:;" onClick={this.handleClick} className="payment-link mb-3 d-inline-block"><span>{T.translate('accountPay.clearSelectedOption')}</span></a>}
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <Select
                                                value={this.props.accountPayCreditCardPaymentAccount}
                                                onChange={this.handleChange}
                                                components={{ Option: PaymentAccountOption, SingleValue: PaymentAccountSingleValue }}
                                                options={this.props.paymentAccounts.filter(x => x.paymentAccountTypeID === 2 || x.paymentAccountTypeID === 3)}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {!this.props.accountPayCreditCardPaymentAccount.paymentAccountID && !this.props.getPaymentStripePK() && <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h6 className="card-title">{T.translate('accountPay.orMakeOneTimePayment')}</h6>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="nameOnAccount">{T.translate('accountPay.nameOnCard')}</label>
                                                    <input type="text" id="nameOnAccount" name="nameOnAccount" value={this.state.nameOnAccount} onChange={this.onChange} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('accountPay.holderNameRequired')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group credit-card-input">
                                                    <label htmlFor="cardNumberValue">{T.translate('accountPay.cardNumber')}</label>
                                                    <InputMask mask={this.getCreditCardMask(this.state.cardNumberValue)} placeholder="#### #### #### ####" id="cardNumberValue" name="cardNumberValue" value={this.state.cardNumberValue} onChange={this.onChange} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="expiration">{T.translate('accountPay.expiration')}</label>
                                                <InputMask mask="99/99" placeholder="MM/YY" id="expiration" name="expiration" value={this.state.expiration} onChange={this.onChange} className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                <div className="invalid-feedback">{T.translate(this.getExpirationDateError(this.state.fields[2]))}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="ccv">{T.translate('accountPay.securityCode')}</label>
                                                <input type="number" id="ccv" name="ccv" maxLength={4} value={this.state.ccv} onChange={this.onChange} className={"form-control " + (this.state.fields[3].isRequiredError ? 'is-invalid' : '')} autoComplete="off" max={4} />
                                                <div className="invalid-feedback">{T.translate(this.getCvvError(this.state.fields[3]))}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="cardZipCode">{T.translate('accountPay.billingZip')}</label>
                                                <input type="text" name="cardZipCode" id="cardZipCode" value={this.state.cardZipCode} onChange={this.onChange} className={"form-control " + (this.state.fields[4].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                <div className="invalid-feedback">{T.translate(this.getZipCodeError(this.state.fields[4]))}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {!this.props.accountPayCreditCardPaymentAccount.paymentAccountID && this.props.getPaymentStripePK() && <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h6 className="card-title">{T.translate('accountPay.orMakeOneTimePayment')}</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group credit-card-input">
                                                <label htmlFor="cardNumberValue">{T.translate('accountPay.cardNumber')}</label>
                                                <div id="txtCreditCardStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 60px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="expiration">{T.translate('accountPay.expiration')}</label>
                                                <div id="txtExpireStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 17px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="ccv">{T.translate('accountPay.securityCode')}</label>
                                                <div id="txtCcvStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 17px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="cardZipCodeStripe">{T.translate('accountPay.billingZip')}</label>
                                                <input type="text" name="cardZipCodeStripe" id="cardZipCodeStripe" value={this.state.cardZipCodeStripe} onChange={this.onChange} className={"form-control"} autoComplete="off" />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Recaptcha
                                            sitekey={this.props.systemProperties.reCaptchaKey}
                                            verifyCallback={this.verifyCallback}
                                            expiredCallback={this.expiredAuthPaymentRecaptcha}
                                            expireTimeMs={10000}
                                        />
                                    </div>
                                    {this.props.enableAuthenticatePaymentInfo && <div className="col-sm-6">
                                        <LoadingButton
                                            type="button"
                                            className="btn btn-outline-dark m-2"
                                            loading={this.props.authorizeAccountPaymentLoading ? 'true' : 'false'}
                                            disabled={!this.state.valid_captcha || this.props.authPaymentExpired || this.props.authPaymentVerified}
                                            onClick={this.verifyCardInfo}>
                                            Authorize
                                        </LoadingButton>
                                    </div>}
                                </div>

                            </div>
                        </div>

                        <div className="clearfix mb-4" />

                        {!this.props.account.isCashOnly && this.props.authPaymentVerified && !this.props.accountPayCreditCardPaymentAccount.paymentAccountID && <div>
                            <div>
                                <p>{T.translate("accountPay.tokenizeCreditDetails")}</p>
                            </div>

                            <div className="checkbox">
                                <label>
                                    <input className="coupon_question sign-up-checkbox mr-1" name="tokenizeCreditDetails" id="tokenizeCreditDetails" value="1" type="checkbox" checked={this.state.tokenizeCreditDetails} onClick={this.onTokenizeCreditDetailsClick} />
                                    {T.translate("accountPay.agreeOnTokenizeCreditDetails")}
                                </label>
                            </div>
                        </div>}

                        {!this.props.account.isCashOnly && this.props.authPaymentVerified && <LoadingButton
                            type="button"
                            className="btn btn-primary mt-0 mb-5"
                            disabled={this.props.paymentLoading || (!this.props.accountPayCreditCardPaymentAccount.paymentAccountID && !this.state.tokenizeCreditDetails)}
                            loading={this.props.paymentLoading ? 'true' : 'false'}
                            onClick={this.pay}>
                            {T.translate('accountPay.submitPayment')}
                        </LoadingButton>}

                        <MessageError>{this.props.paymentErrorMessage}</MessageError>

                        {T.translate('accountPay.byClickingPaymentYouAgree') && <p>
                            {T.translate('accountPay.byClickingPaymentYouAgree')} <ExternalLink href={this.props.systemProperties.marketerTosUrl} className="a-link">{T.translate('accountPay.termsOfService')}</ExternalLink>
                            {T.translate('accountPay.andUnderstandThePrivacyStatement')} <NavLink to="/myaccount/plandocuments" className="a-link">{T.translate('accountPay.yourRightsAsCustomer')}</NavLink>
                        </p>}
                    </div>
                </div>

                <ConfirmModal
                    title={T.translate('accountPay.paymentAccepted')}
                    open={this.props.showAccountPaymentSuccessModal}
                    onToggle={this.goToDashboard}
                    loading={this.props.paymentLoading}>
                    <div>
                        <p>{T.translate('accountPay.yourPaymentHasBeenAccepted')}</p>
                        <p className="font-weight-bold">{T.translate('accountPay.yourPaymentConfirmationNumberIs')}</p>
                        <p>{this.props.accountPayConfirmationNumber}</p>
                    </div>
                </ConfirmModal>

                <ConfirmModal
                    title={T.translate('accountPay.paymentWarning')}
                    open={this.state.open}
                    onToggle={this.close}
                    onConfirm={this.postAccountPay}
                    confirmButtonText={T.translate('accountPay.yesSubmitPayment')}>
                        <div><p>{T.translate('accountPay.areYouSureWantPayMoreAmountDue')}</p></div>
                </ConfirmModal>

                <PaymentSidebar />
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(PayCreditCard);
